import TransactionService from './transaction.service'
import apiutil from '@/util/apiutil'

export default {
  getVLANs(config) {
    const ta = [
      { name: 'nd.bcd.list', old: { is_own: true, sorting_params_list: ['name'] } },
      { name: 'nd.vlan.list', inner_join_ref: { bcd_list: 'default' } }
    ]
    return TransactionService.execute(config, ta)
  },
  getLports(config, vlan) {
    const ta = [
      { name: 'nd.vlan.list', old: { global_pk: vlan.global_pk, fetch_limit: 1 } },
      { name: 'nd.vlan_egress.list', inner_join_ref: { vlan_list: 'default' } },
      { name: 'nd.l_port.list', inner_join_ref: { vlan_egress_list: 'default' } },
      { name: 'nd.l2p_port.list', inner_join_ref: { l_port_list: 'default' } },
      { name: 'nd.p_port.list', inner_join_ref: { l2p_port_list: 'default' } }
    ]
    return TransactionService.execute(config, ta)
  },
  // TODO: the response of this query is rather large (for medium sized querys multiple MB)
  // not all parts are required => this should be filtered
  get_dd_by_bcd_new(config, bcd_name) {
    const ta = [
      {
        name: 'nd.bcd.list',
        idx: 'bcd_list',
        old: {
          name: bcd_name
        }
      },
      {
        name: 'nd.vlan.list',
        idx: 'tmp_vlan_list',
        inner_join_ref: {
          bcd_list: 'default'
        }
      },
      {
        name: 'nd.vlan.list',
        idx: 'tmp_service_vlan_list',
        old: {
          // TODO: make this more generic!
          net_instnc: 'service_1_cn+cs'
        }
      },
      {
        name: 'nd.vlan.list',
        idx: 'vlan_list',
        inner_join_ref: {
          tmp_vlan_list: 'self'
        },
        anti_join_ref: {
          tmp_service_vlan_list: 'self'
        }
      },
      {
        name: 'nd.vlan_egress.list',
        idx: 'egress_list',
        inner_join_ref: {
          vlan_list: 'default'
        },
        old: {
          filter_params_dict: {
            show: true,
            attrs_list: null
          }
        }
      },
      {
        name: 'nd.vlan_egress.list',
        idx: 'tagged_egress_list',
        inner_join_ref: {
          egress_list: 'self'
        },
        old: {
          is_tagged: true,
          filter_params_dict: {
            show: true,
            attrs_list: null
          }
        }
      },
      {
        name: 'nd.l_port.list',
        idx: 'tagged_l_port_list',
        inner_join_ref: {
          tagged_egress_list: 'default'
        },
        old: {
          filter_params_dict: {
            show: true,
            attrs_list: null
          }
        }
      },
      {
        name: 'nd.l2p_port.list',
        idx: 'tagged_l2p_port_list',
        inner_join_ref: {
          tagged_l_port_list: 'default'
        },
        old: {
          filter_params_dict: {
            show: true,
            attrs_list: null
          }
        }
      },
      {
        name: 'nd.p_port.list',
        idx: 'tagged_p_port_list',
        inner_join_ref: {
          tagged_l2p_port_list: 'default'
        }
      },
      {
        name: 'nd.l_port.list',
        idx: 'l_port_list',
        inner_join_ref: {
          egress_list: 'default'
        },
        old: {
          filter_params_dict: {
            show: true,
            attrs_list: null
          }
        }
      },
      {
        name: 'nd.l2p_port.list',
        idx: 'l2p_port_list',
        inner_join_ref: {
          l_port_list: 'default'
        },
        old: {
          filter_params_dict: {
            show: true,
            attrs_list: null
          }
        }
      },
      {
        name: 'nd.p_port.list',
        idx: 'p_port_list',
        inner_join_ref: {
          l2p_port_list: 'default'
        },
        old: {
          filter_params_dict: {
            show: true,
            attrs_list: null
          }
        }
      },
      {
        name: 'nd.p_port.list',
        idx: 'tmp_remote_p_port_list',
        inner_join_ref: {
          p_port_list: 'api_fkey_nd_p_port_conn_dest'
        },
        old: {
          filter_params_dict: {
            show: true,
            attrs_list: null
          }
        }
      },
      {
        name: 'nd.p_port.list',
        idx: 'remote_p_port_list',
        inner_join_ref: {
          tmp_remote_p_port_list: 'api_fkey_nd_p_port_conn_int'
        },
        old: {
          is_edge_node: true
        }
      },
      {
        name: 'nd.p_port.list',
        idx: 'tmp_filtered_remote_p_port_list',
        inner_join_ref: {
          remote_p_port_list: 'self'
        },
        old: {
          // todo: this will all change once ou2p_port exists.
          is_edge_node: true,
          is_connected: false
        }
      },
      {
        name: 'ndcfg.module_type_class.list',
        idx: 'mtc_blkbx',
        old: {
          name: 'BlkBx',
        }
      },
      {
        name: 'ndcfg.module_type.list',
        idx: 'mt_blkbx',
        inner_join_ref: {
          mtc_blkbx: 'default',
        }
      },
      {
        name: 'ndcfg.p_port2module_type.list',
        idx: 'tmp_blkbx_p_port2module_type_list',
        inner_join_ref: {
          mt_blkbx: 'default',
        }
      },
      {
        name: 'nd.p_port.list',
        idx: 'tmp_blkbx_p_port_list',
        inner_join_ref: {
          remote_p_port_list: 'self',
          tmp_blkbx_p_port2module_type_list: 'default',
        }
      },
      {
        name: 'nd.p_port.list',
        idx: 'filtered_remote_p_port_list',
        union_join_ref: {
          tmp_filtered_remote_p_port_list: 'self',
          tmp_blkbx_p_port_list: 'self',
        }
      },
      {
        name: 'nd.module.list',
        idx: 'dd_module_list',
        inner_join_ref: {
          filtered_remote_p_port_list: 'default'
        }
      }
    ]

    return TransactionService.execute(config, ta)
  },
  get_dd_by_bcd(config, bcd_name) {
    const ta = [
      { name: 'nd.bcd.list', old: { name: bcd_name } },
      { name: 'nd.vlan.list', inner_join_ref: { bcd_list: 'default' } },
      { name: 'nd.vlan_egress.list', inner_join_ref: { vlan_list: 'default' } },
      {
        name: 'nd.vlan_egress.list',
        idx: 'tagged_vlan_egress_list',
        inner_join_ref: { vlan_egress_list: 'self' },
        old: { is_tagged: true }
      },
      { name: 'nd.l_port.list', inner_join_ref: { vlan_egress_list: 'default' } },
      { name: 'nd.l2p_port.list', inner_join_ref: { l_port_list: 'default' } },
      { name: 'nd.l_port.list', idx: 'tagged_l_port_list', inner_join_ref: { tagged_vlan_egress_list: 'default' } },
      { name: 'nd.l2p_port.list', idx: 'tagged_l2p_port_list', inner_join_ref: { tagged_l_port_list: 'default' } },
      { name: 'nd.p_port.list', idx: 'tagged_p_port_list', inner_join_ref: { tagged_l2p_port_list: 'default' } },
      {
        name: 'nd.p_port.list',
        idx: 'tagged_dd_p_port_list_all',
        inner_join_ref: {
          tagged_p_port_list: 'api_func_nd_p_port_is_contained_by_conn_id_gfk_list'
        },
        old: {
          is_edge_node: true
        }
      },
      { name: 'nd.p_port.list', inner_join_ref: { l2p_port_list: 'default' } },
      {
        name: 'nd.p_port.list',
        idx: 'dd_p_port_list_all',
        inner_join_ref: {
          p_port_list: 'api_func_nd_p_port_is_contained_by_conn_id_gfk_list'
        },
        old: {
          is_edge_node: true
        }
      },
      { name: 'ndcfg.p_port2module_type.list', inner_join_ref: { dd_p_port_list_all: 'default' } },
      { name: 'ndcfg.module_type.list', old: { class: 'DD' }, inner_join_ref: { p_port2module_type_list: 'default' } },
      {
        name: 'ndcfg.p_port2module_type.list',
        idx: 'filtered_module_types',
        inner_join_ref: {
          module_type_list: 'default',
          dd_p_port_list_all: 'default'
        }
      },
      {
        name: 'nd.p_port.list',
        idx: 'dd_p_port_list',
        inner_join_ref: {
          dd_p_port_list_all: 'self',
          filtered_module_types: 'default'
        },
        old: {
          is_edge_node: true
        }
      },
      {
        name: 'nd.module.list',
        idx: 'dd_module_list',
        inner_join_ref: {
          dd_p_port_list: 'default'
        }
      },
      {
        name: 'nd.module.list',
        idx: 'module_list',
        inner_join_ref: {
          dd_p_port_list_all: 'default'
        }
      }
    ]
    return TransactionService.execute(config, ta)
  },
  find_path_for_p_port(config, port) {
    const mdl_list_ta = {
      name: 'nd.module.list',
      idx: 'start_module_list',
      old: {
        bldg: port.mdl_bldg,
        room: port.mdl_room,
        fq_name: port.mdl_fq_name
      }
    }
    mdl_list_ta.old = apiutil.denullify_dict(mdl_list_ta.old)
    const ta = [
      {
        name: 'nd.p_port.list',
        idx: 'p_port_list_one',
        old: {
          gpk: port.gpk
        },
        inner_join_ref: {
          start_module_list: 'default'
        }
      },
      {
        name: 'nd.p_port.list',
        idx: 'p_port_list',
        inner_join_ref: {
          p_port_list_one: 'api_func_nd_p_port_is_contained_by_conn_id_gfk_list',
        }
      },
      {
        name: 'nd.module.list',
        idx: 'module_list',
        inner_join_ref: {
          p_port_list: 'default'
        }
      },
      {
        name: 'ndcfg.module_type.list',
        idx: 'module_type_list',
        inner_join_ref: {
          module_list: 'default'
        }
      }
    ]
    ta.unshift(mdl_list_ta)
    return TransactionService.execute(config, ta)
  },
  find_bcd_for_dd(config, module_gpk, port_gpk) {
    const ta = [
      {
        name: 'nd.module.list',
        idx: 'cnt_module_list',
        old: {
          gpk: module_gpk,
          fetch_limit: 1
        }
      },
      {
        name: 'nd.p_port.list',
        old: {
          gpk: port_gpk,
          fetch_limit: 1
        },
        inner_join_ref: {
          cnt_module_list: 'default'
        }
      },
      {
        name: 'nd.p_port.list',
        idx: 'resolved_p_ports',
        inner_join_ref: {
          p_port_list: 'api_func_nd_p_port_is_contained_by_conn_id_gfk_list'
        },
        old: {
          is_edge_node: true
        }
      },
      {
        name: 'nd.l2p_port.list',
        inner_join_ref: {
          resolved_p_ports: 'default'
        }
      },
      {
        name: 'nd.l_port.list',
        inner_join_ref: {
          l2p_port_list: 'default'
        }
      },
      {
        name: 'nd.vlan_egress.list',
        inner_join_ref: {
          l_port_list: 'default'
        }
      },
      {
        name: 'nd.vlan.list',
        inner_join_ref: {
          vlan_egress_list: 'default'
        }
      },
      {
        name: 'nd.bcd.list',
        inner_join_ref: {
          vlan_list: 'default'
        }
      },
      {
        name: 'nd.vxlan.list',
        inner_join_ref: {
          bcd_list: 'default'
        }
      },
      {
        name: 'nd.vlan.list',
        idx: 'all_vlan_list',
        inner_join_ref: {
          bcd_list: 'default'
        }
      },
      {
        name: 'nd.module.list',
        idx: 'dd_module_list',
        inner_join_ref: {
          p_port_list: 'default'
        }
      },
      {
        name: 'ndcfg.module_type.list',
        inner_join_ref: {
          dd_module_list: 'default'
        }
      },
      {
        name: 'nd.module.list',
        idx: 'parent_module_list',
        inner_join_ref: {
          cnt_module_list: 'api_func_nd_module_hierarchy_gfk_list_is_subset'
        },
        old: {
          is_child: false
        }
      },
      {
        name: 'nd.room.list',
        idx: 'room_list',
        inner_join_ref: {
          parent_module_list: 'default'
        }
      },
      {
        name: 'nd.bldg.list',
        idx: 'building_list',
        inner_join_ref: {
          room_list: 'default'
        }
      }
    ]

    return TransactionService.execute(config, ta)
  },
  find_remote_p_port_for_p_port(config, module_gpk, p_port_gpk) {
    const ta = [
      {
        name: 'nd.module.list',
        idx: 'o_module_list',
        old: {
          gpk: module_gpk
        }
      },
      {
        name: 'nd.p_port.list',
        idx: 'o_p_port_list',
        old: {
          gpk: p_port_gpk
        },
        inner_join_ref: {
          o_module_list: 'default'
        }
      },
      {
        name: 'nd.p_port.list',
        idx: 'dst_o_p_port_list',
        inner_join_ref: {
          o_p_port_list: 'api_fkey_nd_p_port_conn_dest'
        }
      },
      {
        name: 'nd.p_port.list',
        idx: 'p_port_list',
        inner_join_ref: {
          dst_o_p_port_list: 'api_fkey_nd_p_port_conn_int'
        },
        old: {
          is_edge_node: true
        }
      },
      {
        name: 'nd.module.list',
        idx: 'module_list',
        inner_join_ref: {
          p_port_list: 'default'
        }
      }
    ]
    return TransactionService.execute(config, ta)
  },
  get_bcd_by_name(config, bcd_name) {
    const ta = [
      {
        name: 'nd.bcd.list',
        old: {
          name: bcd_name
        }
      }
    ]
    return TransactionService.execute(config, ta)
  },
  get_bcd_subnets_by_name(config, bcd_name) {
    const ta = [
      {
        name: 'nd.bcd.list',
        old: {
          name: bcd_name
        }
      },
      {
        name: 'nd.ip_subnet.list',
        inner_join_ref: {
          bcd_list: 'default'
        }
      }
    ]
    return TransactionService.execute(config, ta)
  },
  resolve_module_remote_ports(config, module_gpk) {
    const ta = [
      {
        name: 'nd.module.list',
        old: {
          gpk: module_gpk
        }
      },
      {
        name: 'nd.module.list',
        idx: 'all_root_module_list',
        inner_join_ref: {
          module_list: 'api_func_nd_module_hierarchy_gfk_list_is_subset'
        }
      },
      {
        name: 'nd.room.list',
        inner_join_ref: {
          all_root_module_list: 'default'
        }
      },
      {
        name: 'nd.bldg.list',
        inner_join_ref: {
          room_list: 'default'
        }
      },
      {
        name: 'cntl.ot_attr_val.list',
        idx: 'module_attr_list',
        inner_join_ref: {
          module_list: null
        }
      },
      { idx: 'module_attr_def', name: 'cntl.ot_attr_def.list', old: { object_type_fq_name: 'nd.module' } },
      {
        name: 'nd.p_port.list',
        idx: 'local_p_port_list',
        inner_join_ref: {
          module_list: 'default'
        }
      },
      {
        name: 'nd.p_port.list',
        idx: 'int_local_p_port_list',
        inner_join_ref: {
          local_p_port_list: 'api_fkey_nd_p_port_conn_int'
        }
      },
      {
        name: 'nd.p_port.list',
        idx: 'remote_p_port_list',
        inner_join_ref: {
          local_p_port_list: 'api_func_nd_p_port_is_contained_by_conn_id_gfk_list'
        },
        old: {
          is_edge_node: true
        }
      },
      {
        name: 'nd.p_port.list',
        idx: 'int_remote_p_port_list',
        inner_join_ref: {
          remote_p_port_list: 'api_fkey_nd_p_port_conn_int'
        }
      }
    ]
    return TransactionService.execute(config, ta)
  },
  get_device_for_module(config, module_gpk) {
    const ta = [
      {
        name: 'nd.module.list',
        old: {
          gpk: module_gpk
        }
      },
      {
        name: 'nd.module2device.list',
        inner_join_ref: {
          module_list: 'default'
        }
      },
      {
        name: 'dns.fqdn.list',
        inner_join_ref: {
          module2device_list: 'default'
        }
      },
      {
        name: 'nd.device.list',
        inner_join_ref: {
          fqdn_list: 'default'
        }
      },
      {
        name: 'dns.record.list',
        inner_join_ref: {
          fqdn_list: 'default'
        },
        old: {
          sorting_params_list: [
            'type ASC'
          ]
        }
      }
    ]
    return TransactionService.execute(config, ta)
  },
  get_submodule_slots_for_module(config, module_gpk) {
    const ta = [
      {
        name: 'nd.module.list',
        old: {
          gpk: module_gpk
        }
      },
      {
        name: 'nd.slot.list',
        inner_join_ref: {
          module_list: 'default'
        }
      },
      {
        name: 'nd.module.list',
        idx: 'submodule_list',
        inner_join_ref: {
          slot_list: 'api_fkey_nd_module_parent_slot'
        }
      },
      {
        name: 'ndcfg.module_type.list',
        idx: 'submodule_type_list',
        inner_join_ref: {
          submodule_list: 'default'
        }
      }
    ]
    return TransactionService.execute(config, ta)
  },
  load_device_from_fqdn(config, device_fqdn) {
    const ta = [
      {
        name: 'nd.device.list',
        old: {
          fqdn: device_fqdn
        }
      },
      {
        name: 'cntl.ot_attr_val.list',
        idx: 'device_attr_list',
        inner_join_ref: {
          device_list: null
        }
      },
      {
        name: 'cntl.ot_attr_key.list',
        idx: 'device_attr_key_list',
        inner_join_ref: {
          device_attr_list: 'default'
        }
      },
      {
        name: 'cntl.ot_attr_def.list',
        idx: 'device_attr_def_list',
        inner_join_ref: {
          device_attr_key_list: 'default'
        }
      },
      {
        name: 'nd.l_port.list',
        inner_join_ref: {
          device_list: 'default'
        }
      },
      {
        name: 'nd.vlan_egress.list',
        inner_join_ref: {
          l_port_list: 'default'
        }
      },
      {
        name: 'nd.vlan.list',
        inner_join_ref: {
          l_port_list: 'default'
        }
      },
      {
        name: 'nd.vlan.list',
        idx: 'egress_vlan_list',
        inner_join_ref: {
          vlan_egress_list: 'default'
        }
      },
      {
        name: 'nd.l2p_port.list',
        inner_join_ref: {
          l_port_list: 'default'
        }
      },
      {
        name: 'nd.p_port.list',
        idx: 'local_p_port_list',
        inner_join_ref: {
          l2p_port_list: 'default'
        }
      },
      {
        name: 'nd.p_port.list',
        idx: 'remote_p_port_list',
        inner_join_ref: {
          local_p_port_list: 'api_fkey_nd_p_port_conn_dest'
        }
      },
      {
        name: 'nd.p_port.list',
        idx: 'int_remote_p_port_list',
        inner_join_ref: {
          remote_p_port_list: 'api_fkey_nd_p_port_conn_int'
        }
      },
      {
        name: 'dns.fqdn.list',
        inner_join_ref: {
          device_list: 'default'
        }
      },
      {
        name: 'dns.record.list',
        inner_join_ref: {
          fqdn_list: 'default'
        }
      },
      {
        name: 'nd.module2device.list',
        idx: 'module_to_device_list',
        inner_join_ref: {
          fqdn_list: 'default'
        }
      },
      {
        name: 'nd.module.list',
        idx: 'module_list',
        inner_join_ref: {
          module_to_device_list: 'default'
        },
        old: {
          is_child: false
        }
      },
      {
        name: 'nd.room.list',
        idx: 'room_list',
        inner_join_ref: {
          module_list: 'default'
        }
      },
      {
        name: 'nd.bldg.list',
        idx: 'building_list',
        inner_join_ref: {
          room_list: 'default'
        }
      }
    ]
    return TransactionService.execute(config, ta)
  },
  getSites(config) {
    const ta = [
      { name: 'nd.site.list' },
      { name: 'ndcfg.site_type.list' }
    ]
    return TransactionService.execute(config, ta)
  },
  getSite(config, site) {
    const ta = [
      { name: 'nd.site.list', old: { fq_name_list: [site] } },
      { name: 'ndcfg.site_type.list' }
    ]
    return TransactionService.execute(config, ta)
  },
  getBldgs(config, site = null) {
    let old_dict = {}
    if (site) {
      old_dict = { site_fq_name: site }
    }
    const ta = [
      { name: 'nd.bldg.list', old: old_dict },
    ]
    return TransactionService.execute(config, ta)
  },
  getRooms(config, site, bldg) {
    const ta = [
      { name: 'nd.site.list', old: { fq_name_list: [site] } },
      { name: 'nd.bldg.list', old: { number_list: [bldg] }, inner_join_ref: {site_list: 'default'} },
      { name: 'nd.room.list', inner_join_ref: {bldg_list: 'default'} },
    ]
    return TransactionService.execute(config, ta)
  },
  getRoomInfo(config, site, bldg, room_gpk) {
    const ta = [
      { name: 'nd.site.list', old: { fq_name_list: [site] } },
      { name: 'nd.bldg.list', old: { number_list: [bldg] }, inner_join_ref: {site_list: 'default'} },
      { name: 'nd.room.list', inner_join_ref: {bldg_list: 'default'}, old: {gpk: room_gpk} },
      { name: 'nd.module.list', inner_join_ref: {room_list: 'default'} },
      { name: 'nd.p_port.list', inner_join_ref: {module_list: 'default'} },
    ]
    return TransactionService.execute(config, ta)
  }
}
